import React from 'react'
import {useState, useEffect, useCallback} from 'react'
import { debounce } from 'lodash';
import {Spinner} from '@material-tailwind/react'

import {
    Input
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import { mintCheck } from '../util/api';
import { useNavigate } from 'react-router-dom';
import jsonData from '../assets/metadata.json';
import { getMetadata } from '../util/api';

export default function Metadata() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    let datas = await getMetadata();
    let metadata = [];
    for(let data of datas.data) {
      let temp = {
        id: data.inscriptionId,
        meta: {
          name: "Eyes #" + data.number,
          attributes: jsonData[data.number].attributes
        }
      }
      metadata.push(temp);
    }

    console.log(metadata);

    const blob = new Blob([JSON.stringify(metadata, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'metadata.json';
    document.body.appendChild(link);
    link.click();

    // Clean up by revoking the object URL and removing the link element
    URL.revokeObjectURL(url);
    link.remove();
    setLoading(false);
  }

  return (
    <div className="flex sm:flex-row flex-col sm:gap-8 gap-4 mt-5 z-[9999]">
      {
        loading ? 
          <Spinner className="h-12 w-12" />
          :
          <div className="font-bold border-[2px] border-red-900 border-solid py-2 px-4 cursor-pointer rounded-md" onClick={() => {handleDownload()}}>
            Download Metadata
          </div>
      }
    </div>
  )
}
